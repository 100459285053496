<template>
  <div>
    <b-card title="Testing GlitchTip">
      <button @click="throwError">
        Throw error
      </button>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      userData: '',
      firstAkses: true,
    }
  },
  mounted() {
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.firstAkses = JSON.parse(localStorage.getItem('isFirstAkses'))
    if (this.firstAkses == true) {
      localStorage.setItem('isFirstAkses', false)
      window.location.reload()
    }
  },
  methods: {
    throwError() {
      console.log('test')
      throw new Error('Sentry Error')
    },
  },
}
</script>

<style>

</style>
